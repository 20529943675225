@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import "base";
}

@layer components {
  @import "components";
  @import "navigation";
  @import "buttons";
}

@layer utilities {
  @import "utilities";
}
