body.menu-open {
  @apply overflow-hidden pr-0;
}

.header {
  @apply sticky top-0 z-50 border-b bg-body py-[33px] dark:border-darkmode-border dark:bg-darkmode-body;
}

// navbar items
.navbar {
  @apply relative flex flex-wrap items-center justify-between;
}

.collapse-menu {
  @apply fixed right-0 top-0 z-10 mx-0 h-[100vh] w-full max-w-[400px] items-center border-l border-l-dark  bg-body p-6 transition-transform duration-300 ease-in-out dark:bg-darkmode-body lg:static lg:flex lg:h-auto lg:w-auto lg:max-w-full lg:space-x-4 lg:border-l-0 lg:bg-transparent lg:p-0 xl:space-x-8;
}

.navbar-brand {
  @apply text-xl font-semibold text-dark;
  image {
    @apply max-h-full max-w-full;
  }
}

.navbar-nav {
  @apply mt-16 lg:mt-0;
}

.nav-item {
  @apply mb-5 text-left lg:mb-0;

  &.active {
    @apply bg-primary text-white;
  }
}

.nav-link {
  @apply rounded-[30px] px-6 py-2.5 text-left font-secondary text-[13.5px] capitalize  text-dark transition hover:bg-primary hover:text-white dark:text-darkmode-light md:px-3 md:py-3 lg:text-center xl:px-5;

  &.active {
    @apply bg-primary text-white;
  }
}

.nav-dropdown {
  @apply mr-0;
}

.nav-dropdown-list {
  @apply z-10 rounded-lg  border border-transparent bg-body p-4 shadow dark:border-darkmode-border dark:bg-black;
}

.nav-dropdown-item {
  @apply mb-1.5;
}

.nav-dropdown-link {
  @apply min-w-[30px] rounded py-2.5 px-6 text-base font-secondary text-[13.5px] text-dark transition hover:bg-primary/10 hover:text-primary dark:text-darkmode-light;

  &.active {
    @apply bg-primary/10 text-primary;
  }
}

// search style
.search-icon {
  @apply inline-block h-10 w-10 cursor-pointer rounded-full bg-primary p-2.5 text-xl text-white;
}
.search-modal {
  @apply invisible absolute top-0 left-0 right-0 z-10 h-10 bg-body opacity-0 transition dark:bg-black md:h-full;
  .form-input {
    @apply h-full w-full border-0 px-4 text-lg;
  }
  .search-close {
    @apply absolute top-1/2 right-2 -translate-y-1/2 p-3 text-h4;
  }
  &.open {
    @apply visible opacity-100;
  }
}

.light .menu-open {
  .border-border,
  &.border-border {
    @apply border-darkmode-border;
  }
}
