// section style
.section {
  @apply py-8;
}

// container
.container {
  @apply max-w-[1320px] mx-auto;
}

// form style
.form-inputs * {
  @apply mb-5 leading-10;
}

// social icon style
.social-icons {
  @apply space-x-4;
  li {
    @apply inline-block;
    a {
      @apply block h-9 w-9 rounded-full border bg-primary text-center text-white;
      svg {
        @apply m-auto h-9;
      }
    }
  }
}

// form style
.form-input,
.form-textarea {
  @apply border-0 border-b border-border bg-transparent px-0 placeholder:text-xs placeholder:text-light  focus:border-primary focus:ring-transparent dark:border-darkmode-border dark:text-darkmode-light dark:placeholder:text-darkmode-light;
}

// content style
.content {
  @apply prose max-w-none;
  @apply prose-headings:mt-[.5em] prose-headings:mb-[.9em] prose-headings:font-bold prose-headings:text-dark prose-headings:dark:text-white;
  @apply prose-h1:text-h1-sm md:prose-h1:text-h1;
  @apply prose-h2:text-h2-sm md:prose-h2:text-h2;
  @apply prose-h3:text-h3-sm md:prose-h3:text-h3;
  @apply prose-hr:border-border prose-hr:dark:border-darkmode-border;
  @apply prose-p:text-base prose-p:text-text prose-p:dark:text-darkmode-light;
  @apply prose-blockquote:mb-12 prose-blockquote:rounded prose-blockquote:border-0 prose-blockquote:bg-theme-light prose-blockquote:py-1 prose-blockquote:px-12 prose-blockquote:not-italic prose-blockquote:dark:bg-theme-dark prose-blockquote:lg:px-16;
  @apply prose-code:px-1 prose-code:text-primary;
  @apply prose-pre:rounded;
  @apply prose-img:rounded;
  @apply prose-table:rounded;
  @apply prose-strong:text-dark prose-strong:dark:text-darkmode-light;
  @apply prose-a:text-primary prose-a:no-underline hover:prose-a:underline;
  @apply prose-li:text-text prose-li:dark:text-darkmode-light;
  @apply prose-table:overflow-hidden prose-table:border prose-table:border-border prose-table:dark:border-darkmode-border;
  @apply prose-thead:border-border prose-thead:bg-theme-light prose-th:py-4 prose-th:px-4 prose-th:text-dark prose-thead:dark:border-darkmode-border prose-thead:dark:bg-theme-dark prose-th:dark:text-darkmode-light;
  @apply prose-tr:border-border prose-tr:dark:border-darkmode-border;
  @apply prose-td:px-3 prose-td:py-4 prose-td:dark:text-darkmode-light;
  @apply prose-li:marker:dark:text-darkmode-light;

  img + em {
    @apply -mt-4 block text-center text-gray-500;
  }
  blockquote {
    & > p {
      @apply relative text-2xl text-black;
      &::after,
      &::before {
        @apply absolute inline-block h-5 w-5 bg-[url('../../public/images/core/quote.svg')] bg-[length:20px_20px] bg-no-repeat content-[''] lg:h-8 lg:bg-[length:32px_32px] lg:w-8;
      }

      &::before {
        @apply -left-10 top-0 lg:-left-10;
      }

      &::after {
        @apply bottom-0 right-0 rotate-180;
      }
    }
  }
}

//banner
.banner {
  &-title {
    h1 {
      @apply leading-[1] lg:text-[72px] font-bold;
    }

    strong {
      @apply text-primary;
    }

    span {
      @apply font-normal font-bold dark:text-darkmode-light lg:text-[55px] text-dark;
    }
  }
}

//section-title
.section-title {
  @apply mb-6 underline underline-offset-[.2em] decoration-primary;
}

//socials
.socials {
  @apply mx-3 flex items-center justify-center  space-x-3 px-4 lg:justify-start lg:border-x 2xl:space-x-4;
  li {
    @apply inline-flex;
  }
  a {
    @apply inline-block px-2 py-1 text-lg text-dark  hover:text-white dark:text-darkmode-light dark:hover:text-primary;
  }
}

.newsletter-input:focus + svg {
  opacity: 0;
}

.sidebar-socials,
footer .socials {
  @apply border-primary;
}

//experience
.experience {
  ul li {
    @apply relative pl-7;

    &::before {
      @apply absolute left-1 top-2.5 h-[9px] w-[9px] rounded-full border-2 border-white bg-primary shadow-[0px_0px_0px_2px] content-[''] shadow-primary;
    }
  }
}

.active-tab {
  @apply border-[#ffa630] border-b-2;
}

.post {
  img {
    width: 100%;
    height: auto;
  }
}

.embed-refresh body {
  background-color: black !important;
}

#disqus_thread {
  color-scheme: none;
}

.fadeInOutContent {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fadeInOutContent.fade-out {
  opacity: 0;
}

.fadeInOutContent.fade-in {
  opacity: 1;
}
